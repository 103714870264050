import { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import {
    Stack,
    Box,
    TextField,
    Typography,
    Divider,
    Tabs,
    Tab,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
} from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    UserLocation,
    TripPath,
} from "../../../components";
// alerts
import { errorAlert } from "../../../utils/alerts";
// moment
import moment from "moment";
import { translateToArabic } from "../../../utils/translate/englishToArabic";
import ChangeTripStatus from "../../../components/select/ChangeTripStatus";
import { getUserRole } from "../../../utils/roles/getUserRole";
import { Link } from "react-router-dom";
const OrderDetails = () => {
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const { orderId } = useParams();
    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState({});
    const [activeTab, setActiveTab] = useState("trip");
    const role = getUserRole(userData);
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/${role}/trips/${orderId}`)
            .then((res) => {
                setOrderDetails(res.data);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);
    if (loading) return <RingsLoader />;
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="تفاصيل الطلب" />
            <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
            >
                <Tab value="trip" label="بيانات الرحلة" />
                <Tab value="user" label="بيانات المستخدم و السائق" />
                <Tab value="cost" label="بيانات التكلفة" />
                <Tab value="path" label="بيانات مسار االرحلة" />
            </Tabs>
            <Stack spacing="10px" width="100%">
                {activeTab === "user" && (
                    <Stack
                        direction="row"
                        gap="32px"
                        justifyContent="center"
                        flexWrap="wrap"
                    >
                        <Card
                            sx={{
                                width: "300px",
                                maxWidth: 345,
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="body2" textAlign="start" p={1}>
                                المستخدم
                            </Typography>
                            <Divider />
                            <CardMedia
                                component="img"
                                alt={orderDetails?.customer?.name}
                                height="140"
                                image={
                                    orderDetails?.customer?.img || "/logo1.png"
                                }
                                sx={{
                                    objectFit: "contain",
                                }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="body1">
                                    {orderDetails?.customer?.name}
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    {orderDetails?.customer?.phone}
                                </Typography>
                            </CardContent>
                            <Divider />
                            <CardActions sx={{ justifyContent: "flex-end" }}>
                                <Button
                                    size="small"
                                    component={Link}
                                    to={`/edit-user/${orderDetails?.customer?.id}`}
                                >
                                    تفاصيل
                                </Button>
                            </CardActions>
                        </Card>
                        <Card
                            sx={{
                                width: "300px",
                                maxWidth: 345,
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="body2" textAlign="start" p={1}>
                                السائق
                            </Typography>
                            <Divider />
                            <CardMedia
                                component="img"
                                alt={orderDetails?.driver?.name}
                                height="140"
                                image={
                                    orderDetails?.driver?.img || "/logo1.png"
                                }
                                sx={{
                                    objectFit: "contain",
                                }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="body1">
                                    {orderDetails?.driver?.name}
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    {orderDetails?.driver?.phone}
                                </Typography>
                            </CardContent>
                            <Divider />
                            <CardActions sx={{ justifyContent: "flex-end" }}>
                                <Button
                                    size="small"
                                    component={Link}
                                    to={`/edit-driver/${orderDetails?.driver?.id}`}
                                >
                                    تفاصيل
                                </Button>
                            </CardActions>
                        </Card>
                    </Stack>
                )}
                {activeTab === "trip" && (
                    <Stack gap="32px">
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            gap="24px"
                        >
                            <Typography variant="h5">
                                رقم الرحلة : {orderDetails.friendly_id}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            gap="16px"
                            alignItems="flex-start"
                            flexWrap="wrap"
                        >
                            <Stack gap="16px" flex="2" minWidth="300px">
                                <CardList moreSx={{ width: "100%" }}>
                                    <CardListItem
                                        text="طول مسار السائق بالمتر"
                                        value={
                                            orderDetails?.driver_path_length ??
                                            0
                                        }
                                    />
                                    <CardListItem
                                        text="طول الامتار الاضافية للوصول للزبون بالمتر "
                                        value={
                                            orderDetails?.pickup_extended_path_lenth ??
                                            0
                                        }
                                    />
                                    <CardListItem
                                        text="الامتار الاضافية"
                                        value={
                                            orderDetails?.extended_meters ?? 0
                                        }
                                    />
                                    <CardListItem
                                        text="طول المسار الاضافي بالمتر"
                                        value={
                                            orderDetails?.extended_path_length ??
                                            0
                                        }
                                    />
                                    <CardListItem
                                        text="طول مسار الرحلة بالمتر"
                                        value={orderDetails?.path_length ?? 0}
                                    />
                                </CardList>
                                <CardList moreSx={{ width: "100%" }}>
                                    <CardListItem
                                        text="تاريخ انشاء الطلب"
                                        value={
                                            orderDetails.created_at
                                                ? moment(
                                                      orderDetails.created_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : ""
                                        }
                                    />
                                    <CardListItem
                                        text="وقت تحرك السائق"
                                        value={
                                            orderDetails.driver_claimed_at
                                                ? moment(
                                                      orderDetails.driver_claimed_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : "لا يوجد"
                                        }
                                    />
                                    <CardListItem
                                        text="وقت وصول السائق"
                                        value={
                                            orderDetails.driver_arrived_at
                                                ? moment(
                                                      orderDetails.driver_arrived_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : "لا يوجد"
                                        }
                                    />
                                    <CardListItem
                                        text="وقت الغاء السائق للطلب"
                                        value={
                                            orderDetails.driver_cancelled_at
                                                ? moment(
                                                      orderDetails.driver_cancelled_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : "لا يوجد"
                                        }
                                    />
                                    <CardListItem
                                        text="وقت الغاء الزبون للطلب"
                                        value={
                                            orderDetails.customer_cancelled_at
                                                ? moment(
                                                      orderDetails.customer_cancelled_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : "لا يوجد"
                                        }
                                    />
                                    <CardListItem
                                        text="وقت  ركوب العميل"
                                        value={
                                            orderDetails.customer_pickup_at
                                                ? moment(
                                                      orderDetails.customer_pickup_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : "لا يوجد"
                                        }
                                    />
                                    <CardListItem
                                        text="وقت  انزال العميل"
                                        value={
                                            orderDetails.customer_dropoff_at
                                                ? moment(
                                                      orderDetails.customer_dropoff_at
                                                  ).format(
                                                      "YYYY/MM/DD -- HH:mm"
                                                  )
                                                : "لا يوجد"
                                        }
                                    />
                                </CardList>
                            </Stack>
                            <Stack flex="1" minWidth="300px" gap="16px">
                                <CardList moreSx={{ width: "100%" }}>
                                    <CardListItem
                                        text="التكلفة الاضافية"
                                        value={orderDetails?.cost_penalty ?? 0}
                                    />
                                    <CardListItem
                                        text="اجمالي التكلفة"
                                        value={orderDetails?.total_cost ?? 0}
                                    />
                                </CardList>

                                {orderDetails?.start_address?.location
                                    ?.coordinates?.length ? (
                                    <CardList>
                                        <Stack variant="subtitle2">
                                            <Typography
                                                variant="subtitle2"
                                                fontWeight="bold"
                                            >
                                                نقطة البداية
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                variant="subtitle2"
                                                gap="8px"
                                                alignItems="center"
                                            >
                                                {
                                                    orderDetails?.start_address
                                                        ?.area?.name
                                                }
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        width: "8px",
                                                        height: "8px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "#ddd",
                                                    }}
                                                />
                                                {
                                                    orderDetails?.start_address
                                                        ?.city?.name
                                                }
                                            </Stack>
                                        </Stack>
                                        <UserLocation
                                            containerStyles={{
                                                width: "100%",
                                                height: "200px",
                                            }}
                                            points={{
                                                lat: orderDetails?.start_address
                                                    ?.location?.coordinates[1],
                                                lng: orderDetails?.start_address
                                                    ?.location?.coordinates[0],
                                            }}
                                        />
                                    </CardList>
                                ) : (
                                    <></>
                                )}
                                {orderDetails?.end_address?.location
                                    ?.coordinates?.length ? (
                                    <CardList>
                                        <Stack variant="subtitle2">
                                            <Typography
                                                variant="subtitle2"
                                                fontWeight="bold"
                                            >
                                                نقطة النهاية
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                variant="subtitle2"
                                                gap="8px"
                                                alignItems="center"
                                            >
                                                {
                                                    orderDetails?.end_address
                                                        ?.area?.name
                                                }
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        width: "8px",
                                                        height: "8px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "#ddd",
                                                    }}
                                                />
                                                {
                                                    orderDetails?.end_address
                                                        ?.city?.name
                                                }
                                            </Stack>
                                        </Stack>
                                        <UserLocation
                                            containerStyles={{
                                                width: "100%",
                                                height: "200px",
                                            }}
                                            points={{
                                                lat: orderDetails?.end_address
                                                    ?.location?.coordinates[1],
                                                lng: orderDetails?.end_address
                                                    ?.location?.coordinates[0],
                                            }}
                                        />
                                    </CardList>
                                ) : (
                                    <></>
                                )}
                                <Box
                                    width="100%"
                                    sx={{
                                        "& > div": {
                                            width: "100%",
                                            backgroundColor: "#fff",
                                            boxShadow: "0px 6px 12px #00000020",
                                        },
                                    }}
                                >
                                    <ChangeTripStatus
                                        value={orderDetails.status}
                                        orderId={orderDetails.id}
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
                {activeTab === "cost" && (
                    <Stack direction="row" gap="32px" alignItems="flex-start">
                        <CardList
                            moreSx={{
                                flex: "1",
                                minWidth: "300px",
                                width: "100%",
                            }}
                        >
                            <CardListItem
                                text="تكلفة المتر للمسافة الاضافية قبل نقطة البداية"
                                value={
                                    orderDetails.cost_pickup_extended_meters ??
                                    0
                                }
                            />
                            <CardListItem
                                text="تكلفة المتر للمسافة الاضافية بعد نقطة النهاية"
                                value={
                                    orderDetails.cost_dropoff_extended_meters ??
                                    0
                                }
                            />
                            <CardListItem
                                text="تكلفة الامتار الاضافية"
                                value={orderDetails.extended_meters ?? 0}
                            />
                            <CardListItem
                                text="معدل تكلفة الساعة"
                                value={orderDetails.hourly_rate ?? 0}
                            />
                            <CardListItem
                                text="تكلفة اجمالي الساعات"
                                value={orderDetails.hours_total ?? 0}
                            />
                            <CardListItem
                                text="نسبة الزيادة لطلب سيارة فارهة"
                                value={orderDetails.cost_luxury_percent ?? 0}
                            />

                            <CardListItem
                                text="اقل مسافة عن نقطة الوصول لانهاء الرحلة"
                                value={orderDetails.min_dropoff_distance ?? 0}
                            />
                            <CardListItem
                                text="اقل مسافة لتحديد وصول السائق"
                                value={orderDetails.min_arrive_distance ?? 0}
                            />
                        </CardList>
                        <Stack flex="1" minWidth="300px" gap="32px">
                            <CardList moreSx={{ width: "100%" }}>
                                <CardListItem
                                    text="ارباح التطبيق"
                                    value={orderDetails.app_profit ?? 0}
                                />
                                <CardListItem
                                    text="سعر المنطقة"
                                    value={orderDetails.area_price ?? 0}
                                />
                                <CardListItem
                                    text="السعر الاساسي"
                                    value={orderDetails.base_price ?? 0}
                                />
                            </CardList>
                            <CardList>
                                <CardListItem
                                    text="معدل تكلفة المتر"
                                    value={orderDetails.cost_meter_rate ?? 0}
                                />
                                <CardListItem
                                    text="تكلفة الغرامة"
                                    value={orderDetails.cost_penalty ?? 0}
                                />

                                <CardListItem
                                    text="اجمالي التكلفة"
                                    value={orderDetails.total_cost ?? 0}
                                />
                            </CardList>
                        </Stack>
                    </Stack>
                )}
                {activeTab === "path" && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="20px"
                        flexWrap="wrap"
                        width="100%"
                    >
                        <Box flex="1" minWidth="280px">
                            {orderDetails.status == "completed" ? (
                                <TripPath
                                    path={orderDetails.path.coordinates}
                                    driverPath={
                                        orderDetails?.driver_path
                                            ?.coordinates ?? []
                                    }
                                    pickupExtendedPath={
                                        orderDetails?.pickup_extended_path
                                            ?.coordinates ?? []
                                    }
                                    extendedPath={
                                        orderDetails?.extended_path
                                            ?.coordinates ?? []
                                    }
                                />
                            ) : (
                                <p>رحلة غير مكتملة</p>
                            )}
                        </Box>
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

export default OrderDetails;

const CardListItem = ({ text, value }) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="16px"
            p={1}
        >
            <Typography variant="body2">{text}</Typography>
            <Typography variant="body2">{value}</Typography>
        </Stack>
    );
};

const CardList = ({ moreSx, children }) => {
    return (
        <Stack
            sx={{
                boxShadow: "0px 6px 12px #00000020",
                borderRadius: "8px",
                gap: "4px",
                padding: "16px",
                ...moreSx,
            }}
            divider={<Divider flexItem />}
        >
            {children}
        </Stack>
    );
};
