import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import EnhancedAxios from "../../api/EnhancedAxios";
import { errorAlert } from "../../utils/alerts";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// hooks
import { usePageParams } from "../../hooks/usePageParams";
const OrderStatusFilter = ({ setFilterValues }) => {
    const { page, setPage } = usePageParams();
    const [types, setTypes] = useState([
        {
            value: "short",
            label: "مشوار",
        },
        {
            value: "long",
            label: "رحلة",
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleAutocompleteChange = (e, newValue) => {
        setFilterValues((prev) => ({
            ...prev,
            orderType: newValue ? newValue.value : "",
        }));
        setPage("1");
    };

    // useEffect(() => {
    //     EnhancedAxios(null, null)
    //         .get(
    //             `/categories?paginate=9999&filters=parent_id:b3632560-b4bd-4009-9182-42ec15078219`
    //         )
    //         .then((res) => {
    //             console.log(res.data);
    //             setTypes(res.data.data);
    //             setLoading(false);
    //         })
    //         .catch((error) => errorAlert(error));
    // }, []);
    return (
        <Autocomplete
            sx={{ flex: "1", minWidth: "230px" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
                option.value == value.value
            }
            getOptionLabel={(option) => option.label}
            options={types}
            loading={loading}
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="نوع الطلب"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <AiOutlineLoading3Quarters /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default OrderStatusFilter;
