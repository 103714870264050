import React, { useState } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { usePageParams } from "../../hooks/usePageParams";
import { translateToArabic } from "../../utils/translate/englishToArabic";
const orderStatusOptions = [
    {
        label: "الكل",
        value: "",
    },
    {
        label: translateToArabic("requested"),
        value: "requested",
    },
    {
        label: translateToArabic("claimed"),
        value: "claimed",
    },
    {
        label: translateToArabic("arrived"),
        value: "arrived",
    },
    {
        label: translateToArabic("ongoing"),
        value: "ongoing",
    },
    {
        label: translateToArabic("completed"),
        value: "completed",
    },
    {
        label: translateToArabic("cancelled"),
        value: "cancelled",
    },
    {
        label: translateToArabic("extended"),
        value: "extended",
    },
    // "requested",
    // "claimed",
    // "arrived",
    // "ongoing",
    // "completed",
    // "cancelled",
    // "extended",
];
const OrderStatusFilter = ({ setFilterValues }) => {
    const { page, setPage } = usePageParams();
    const [value, setValue] = useState(null);
    const handleChange = (e) => {
        setValue(e.target.value);
        setFilterValues((prev) => ({ ...prev, orderStatus: e.target.value }));
        setPage("1");
    };
    return (
        <Box minWidth="230px" flex="1">
            <FormControl fullWidth>
                <InputLabel>حالة الطلب</InputLabel>
                <Select
                    value={value ?? ""}
                    label="حالة الطلب"
                    onChange={handleChange}
                >
                    {orderStatusOptions.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default OrderStatusFilter;
