import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// components
import { LinkButton, PageTitle, RingsLoader } from "../../../components";
// map
import {
    GoogleMap,
    Marker,
    InfoWindow,
    useLoadScript,
} from "@react-google-maps/api";
// fetch
import EnhancedAxios from "../../../api/EnhancedAxios";
// utils
import { getUserRole } from "../../../utils/roles/getUserRole";
// icons
import { MdOutlineDirectionsCar, MdOutlinePhoneIphone } from "react-icons/md";
// alerts
import { errorAlert } from "../../../utils/alerts";
// mui
import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// const carIcon = {
//     url: "/car.png",
//     scaledSize: new window.google.maps.Size(40, 40), // Adjust size if needed
// };

const mapContainerStyle = {
    width: "100%",
    height: "500px",
};

const ActiveDrivers = () => {
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const [center, setCenter] = useState({ lat: 31, lng: 16 });
    const role = getUserRole(userData);
    const [loading, setLoading] = useState(true);
    const { isLoaded, error } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }); // script loader for map

    const [selectedDriver, setSelectedDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const getActiveDrivers = () => {
        EnhancedAxios(null, userToken)
            .get(`/${role}/users/available-drivers `)
            .then((res) => {
                console.log(res.data);
                setDrivers(res?.data?.data || []);
                // setCenter({
                //     lat: res?.data?.data[0]?.location?.coordinates[1],
                //     lng: res?.data?.data[0]?.location?.coordinates[0],
                // });
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    };
    useEffect(() => {
        setLoading(true);
        getActiveDrivers();
        const interval = setInterval(getActiveDrivers, 10000);

        return () => clearInterval(interval);
    }, []);

    if (error) return <div>Error loading maps</div>;
    if (!isLoaded || loading) return <RingsLoader />;
    return (
        <Stack gap="16px">
            <PageTitle title="السائقين النشطين" />
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={7}
            >
                {drivers?.map((driver) => (
                    <Marker
                        key={driver.id}
                        position={{
                            lat: driver.location?.coordinates[1],
                            lng: driver.location?.coordinates[0],
                        }}
                        icon={{
                            url: "/car.png",
                            scaledSize:
                                window?.google && window.google.maps
                                    ? new window.google.maps.Size(40, 40)
                                    : null,
                        }}
                        onClick={() => setSelectedDriver(driver)}
                    />
                ))}

                {selectedDriver && (
                    <InfoWindow
                        position={{
                            lat: selectedDriver.location?.coordinates[1],
                            lng: selectedDriver.location?.coordinates[0],
                        }}
                        onCloseClick={() => setSelectedDriver(null)}
                    >
                        <Stack
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign={"center"}
                            minWidth="300px"
                            overflow="hidden"
                        >
                            <Avatar
                                src={selectedDriver?.thumb}
                                alt={selectedDriver?.name}
                                sx={{ width: 64, height: 64 }}
                            />
                            <Typography variant="body1">
                                {selectedDriver.name}
                            </Typography>
                            <Typography variant="body2">
                                <MdOutlinePhoneIphone /> {selectedDriver.phone}
                            </Typography>
                            <Typography variant="body2">
                                <MdOutlineDirectionsCar />{" "}
                                {selectedDriver.car_type}
                            </Typography>
                            <Button
                                variant="outlined"
                                component={Link}
                                to={`/edit-driver/${selectedDriver.id}`}
                            >
                                تعديل
                            </Button>
                        </Stack>
                    </InfoWindow>
                )}
            </GoogleMap>
        </Stack>
    );
};

export default ActiveDrivers;
